import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import { AjaxAct } from '@/utils/ajax1'


export default new Vuex.Store({
  state: {
	gData: {
	 usprice: 0,		// USDT匯率
      buy1: 0,			// 黄金條塊-買入		// goldpriceB
      sell1: 0,			// 黄金條塊-賣出		// goldpriceBS
      buy2: 0,			// 飾金-買入				// goldprice
      sell2: 0,	  		// 飾金-賣出				// goldpriceS	       
		},
	//nationalGoldBuy:{japanp:0,singaporep:0,philipingp:0,vietnamp:0,thailandp:0,taiwanAsp:0}, 
	
	//nationalGoldBuy:{}, 
},

  // getters: {
		// getUsprice: (state) => {
			// return state.gData.usprice;
		// },
  // },
  mutations: {
		SET_USPRICE(state, data) { state.gData.usprice =data },
		// 黄金條塊-買入
		SET_BUY1(state, data) { state.gData.buy1 = data },
		// 黄金條塊-賣出
		SET_SELL1(state, data) { state.gData.sell1 = data },
		// 飾金-買入
		SET_BUY2(state, data) { state.gData.buy2 = data },
		// 飾金-賣出
		SET_SELL2(state, data) { state.gData.sell2 = data },
        //SET_NATIONALPRICE(state, data) { state.nationalGoldBuy=data},
		//SET_NATIONALPRICE(state, data) { state.nationalGoldBuy.japanp= data.Japanp,state.nationalGoldBuy.singaporep= data.Singaporep,state.nationalGoldBuy.philipingp=data.Philipingp,state.nationalGoldBuy.vietnamp= data.Vietnamp,state.nationalGoldBuy.thailandp= data.Thailandp,state.nationalGoldBuy.taiwanAsp= data.TaiwanAsp},
  },
  actions: {
		/* 取得USDT匯率 */
		async fetchUsprice({commit}) {
		// async fetchUsprice({ commit, state }, payload) {
			let a1 	= await AjaxAct("usprice"),			
			data= a1.usprice[0].usprice
				 //console.warn('取得USDT匯率-a1: ', a1);	// @@
			commit('SET_USPRICE',data)
		},
		/* 取得-黄金條塊-買入 */
		async fetchBuy1({commit}) {
			let a1 = await AjaxAct("goldpriceB"),
			data	= a1.goldpriceB[0].goldpriceB
			commit('SET_BUY1', data)
		},
		/* 取得-黄金條塊-賣出 */
		async fetchSell1({commit}) {
			let a1 = await AjaxAct("goldpriceBS"),
			data	= a1.goldpriceBS[0].goldpriceBS
			commit('SET_SELL1', data)
		},
		/* 取得-飾金-買入 */
		async fetchBuy2({commit}) {
			let a1 	= await AjaxAct("goldprice")
				,data	= a1.goldprice[0].goldprice	// console.warn('取得飾金買入-a1: ', a1);	// @@
			commit('SET_BUY2', data)
		},
		/* 取得-飾金-賣出 */
		async fetchSell2({commit}) {
			let a1 	= await AjaxAct("goldpriceS")
				,data	= a1.goldpriceS[0].goldpriceS
			commit('SET_SELL2', data)
		},
		// async fetchNationalGoldBuy({commit}) {
		// 	let a1 	= await AjaxAct("nationalGoldBuy"),
		// 	data	= a1
		// 	commit('SET_NATIONALPRICE', data)
		// },
		/* 取得-所有資料 */
	 async fetchAllData({dispatch}) {
      await dispatch('fetchUsprice')			// 取得USDT匯率
      await dispatch('fetchBuy1')
      await dispatch('fetchSell1')
      await dispatch('fetchBuy2')
      await dispatch('fetchSell2')
	  //await dispatch('fetchNationalGoldBuy')
			return new Promise(resolve => resolve())
		},

  },
})
