<template>
  <div class="calculate-tool-wrap">
    <div class="tool-header">{{ goldUnitText }}試算</div>
    <div class="input-grpup">
      <!-- 單位 -->
      <div class="input-col">
        <div class="input-title">
          單位:
          <!-- 單位選擇的選單, 單位欄位才要出現 -->
          <select v-model="calculateUnit">
            <option v-for="uint in unitList" :value="uint.eng">
              {{ uint.chinese }}
            </option>
          </select>
        </div>
        <div>
          <input class="input-content" type="number" v-model="inputWeight" placeholder="重量" />
        </div>
        <div class="remark-col"></div>
      </div>
      <!--  -->
      <div class="input-col">
        <div class="input-title">當日公告收價</div>
        <div class="display-content-warp">
          <div class="display-content">{{ displayChoosenUnit }}</div>
        </div>
        <div class="remark-col"></div>
      </div>
      <div class="input-col">
        <div class="input-title">收購金額</div>
        <div class="display-content-warp">
          <div class="display-content">{{ calculatedAmount }}</div>
        </div>
        <div class="remark-col"></div>
      </div>
<!-- 
      <div class="input-col">
        <div class="input-title">收購金額</div>
        <div class="display-content-warp">
          <div class="display-content">{{ calculatedAmount }}</div>
        </div>
        <div class="remark-col memo-text"><span v-if="goldUnit == 'gold'">已計耗損5%</span></div>
      </div>
      <div class="input-col">
        <div class="input-title">USDT換算</div>
        <div class="display-content-warp">
          <div class="display-content">{{ calculatedUsdt }}</div>
        </div>
        <div class="remark-col memo-text">匯率：{{ UsdtRate }}</div>
        // <div class="usdt-rate">(匯率：{{ UsdtRate }})</div>
      </div>
 -->
    </div>
    <hr />
    <div class="submit-area">
      <span>例重: 一兩二錢三分四厘 = 12.34 (在框裡輸入重量後，點擊送出計算即可。)</span>
      <button @click="submitToCalculate" class="submit-btn">送出計算</button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tool-header {
  text-align: center;
  padding: 5px 0;
  font-size: 1.25rem;
  font-weight: bold;
  color: white;
  background-color: #9e7d56;
}

.calculate-tool-wrap {
  font-family: DFKai-sb;
  margin: 30px 0 30px 0;
}

hr {
  height: 2px;
  background-color: rgb(190, 190, 190);
}

select {
  // border: #9e7d56 1px solid;
  border: #121212 1px solid;
  border-radius: 5px;
  padding: 1px;
  font-weight: 600;
  // color: #9e7d56;
  color: #121212;
}

.submit-area {
  padding: 35px 10px;
  font-size: 1.15rem;
  font-weight: 600;
  color: #423d38;
}

.submit-btn {
  border: none;
  font-size: 1rem;
  padding: 5px 35px;
  background-color: rgb(230, 60, 60);
  border-radius: 5px;
  color: rgb(240, 240, 240);
  margin-left: 20px;
  cursor: pointer;

  &:hover {
    background-color: rgb(200, 20, 20);
  }
}

.remark-col {
  height: 50px;
}

@media screen and (max-width: 550px) {
}
</style>

<script>
export default {
  name: 'CalculateTool',
  props: {
    goldUnit: String,
  },
  data() {
    return {
      state: this.$store.state,
      inputWeight: '', //輸入重量
      calculatedAmount: '', //計算金額
      calculatedUsdt: '', //換算USDT
      unitList: [
        { eng: 'gram', chinese: '克' },
        { eng: 'liang', chinese: '兩' },
        { eng: 'penny', chinese: '錢' },
        { eng: 'kg', chinese: '公斤' },
      ],
      calculateUnit: 'penny',
    };
  },
  computed: {
    gData() {
			return this.state.gData
    },
    isGold() {
			return this.goldUnit == 'gold'
    },
		/* 當日公告收價 */
    displayChoosenUnit() {
			const calUnit = this.calculateUnit
				,basePrice 	= this.isGold ? this.gData.buy2 : this.gData.buy1
// console.warn('displayChoosenUnit-calUnit, basePrice: ', calUnit, basePrice);	// @@
			if (calUnit == 'gram') {
				return (basePrice / 3.75).toFixed(0);
			} else if (calUnit == 'liang') {
				return (basePrice * 10).toFixed(0);
			} else if (calUnit == 'kg') {
				return ((basePrice / 3.75) * 1000).toFixed(0);
			// } else {
			}
      return basePrice.toFixed(0);
    },

    // 單位的文字
    goldUnitText() {
      return this.isGold ? '飾金' : '黃金條塊';
    },
  },
  methods: {
    // 計算
    submitToCalculate: function () {
			const nWeight = parseFloat(this.inputWeight)
      if (!nWeight) {return;}

      // 飾金要另外再 *0.95
      let calculateAnswer = this.displayChoosenUnit * nWeight;
// console.warn('計算-nWeight, calculateAnswer: ', nWeight, calculateAnswer);	// @@
      this.calculatedAmount =
			// this.goldUnit == 'gold' ? (calculateAnswer * 0.95).toFixed(0) : calculateAnswer.toFixed(0);
			this.goldUnit == 'gold' ? calculateAnswer.toFixed(0) : calculateAnswer.toFixed(0);

			const usdtRate 	= this.gData.usprice
      this.calculatedUsdt = (this.calculatedAmount / usdtRate).toFixed(2);

      alert('完成');
    },
    // async fetchAllData() {
			// await this.$store.dispatch('fetchAllData')
			// console.warn('fetchAllData-gData: ', this.gData);	// @@
    // },
  },
	/* 只有第1次進入才來 */
  async beforeRouteEnter(to, from, next) {
		// next()
		next(vm => {
			console.warn('beforeRouteUpdate-vm: ', vm);	// @@
			// vm.hasAD = hasBanner;
			// if (!hasBanner) {
				// vm.toNext();
			// }
		})
  },
  // beforeCreate() {
    // this.fetchAllData();
  // },
  // created() {
		// console.log('created-gData: ', this.gData);	// @@
  // },
};
</script>