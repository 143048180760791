import axios from 'axios'
// import store from '@/store'


// axios.defaults.withCredentials = true    //NG: CROS
const SERVER1 = 'http://localhost:3000/api/price/'
	,SERVER2 		= 'https://conversion.jh-gold.com/api/price/'
	//,IsDev 			= /localhost/.test(location.hostname)
	//,IsProd 		= /conversion/.test(location.hostname)
	//,BASEURL 		= (IsDev||IsProd) ? SERVER2 : SERVER1
	,BASEURL 		= SERVER2		// PS: 測試站有跨域問題

var IsTouch = window.ontouchstart !== undefined;
axios.create({
  baseURL: BASEURL, // api 的 base_url
  timeout: 60000, // 请求超时时间
  withCredentials: true, // send cookies when cross-domain requests
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;application/json; charset=UTF-8;'
  },
})

// console.error('fun1-store: ', store);	// @@

export async function AjaxAct(act, params) {
	// console.warn('AjaxAct_act: '+act, params);	// @@

	const errFun = function (err) {
		console.error('API錯誤: ', err);
		IsTouch && 	alert('API錯誤: '+err.message);	// @@
	};

const url = BASEURL+act
  let res1 = null;
	if (params) {
		res1 = await axios.post(url, params).catch(errFun);
		//res1 = axios.post(url, params).then((response) => {return response}).catch((errFun)=>{alert('post失敗');});
			// .catch ((err) => {console.error('API錯誤: ', err);});
			//return res1.status == 200 ? res1.data	: '';
	} else {
		res1 =await axios.get(url).catch(errFun);
		//res1 =  axios.get(url).then((response) => {return JSON.parse(JSON.stringify(response))}).catch((errFun)=>{alert('get失敗');});
	}

	console.log('AjaxAct-res1: ', res1.data);	// @@
	return res1.status == 200 ? res1.data:'';
	//return  res1.data	;
};

