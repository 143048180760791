import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/main.scss';

Vue.config.productionTip = false

// store.dispatch('fetchAllData').then(response => {
	new Vue({
		router,
		store,
		render: h => h(App)
	}).$mount('#app')
// }).catch(err => {
	// console.error('Error: ', err);	// @@
// })

