import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/updatePrice',
    name: 'updatePrice',
    component: () => import(/* webpackChunkName: "about" */ '../views/UpdatePrice.vue')
  },
  {
    path: '/todayPrice',
    name: 'todayPrice',
    component: () => import('@/views/TodayPrice.vue')
  },
  {
    path: '/todayPrice2',
    name: 'todayPrice2',
    component: () => import('@/views/TodayPrice2.vue')
  },
  // {
  //   path: '/nationalPrice',
  //   name: 'nationalPrice',
  //   component: () => import('@/views/NationalPrice.vue')
  // },
]

const router = new VueRouter({
  routes
})

export default router
