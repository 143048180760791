<template>
  <div class="home" id="HomeView">
    <div class="calculate-banner"></div>

    <div class="main-content">
      <HomeDescription />
      <CalculateTool goldUnit="bullion" />
      <CalculateTool goldUnit="gold" />
      <RemarkText />

      <div class="btn-wrap">
        <button class="home-btn" @click="goHome">返回首頁</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.calculate-banner {
  width: 100vw;
  height: 25vw;
  background-image: url('../assets/banner.png');
  background-position: center;
  background-size: cover;
}

.main-content {
  box-sizing: border-box;
  width: 100vw;
  padding: 50px 120px;
  background-color: #fff9f1;
}

.btn-wrap {
  margin-top: 20px;
  text-align: center;
}

.home-btn {
  width: 200px;
  padding: 8px;
  border-radius: 5px;
  background-color: #9e7d56;
  color: white;
  font-weight: bolder;
  cursor: pointer;
}

@media screen and (max-width: 1050px) {
  .main-content {
    padding: 15px 0px;
  }
}
</style>

<script>
import store from 'src/store'
import CalculateTool from '@/components/CalculateTool.vue';
import HomeDescription from '@/components/HomeDescription.vue';
import RemarkText from '@/components/RemarkText.vue';

export default {
  name: 'HomeView',

  components: {
    CalculateTool,
    HomeDescription,
    RemarkText,
  },
  // data() {
    // return {
      // // state: this.$store.state,
    // };
  // },
  methods: {
    // 返回首頁
    goHome() {
      console.log('home');
      location.href = 'https://jh-gold.com';
      // https://jh-gold.com/
    },
    // async fetchAllData() {
			// await this.$store.dispatch('fetchAllData')
			// console.warn('fetchAllData-gData: ', this.gData);	// @@
    // },
  },
	/* 只有第1次進入才來 */
  async beforeRouteEnter(to, from, next) {
		await store.dispatch('fetchAllData')
		// console.warn('fetchAllData-gData: ', store.state.gData);	// @@

		next()
  },
};
</script>